 import Member from '../comps/Member.jsx';
 import Row from 'react-bootstrap/Row';

 function Team() {
    return(
        <div>
        <h2>Unser Team 2022/2023</h2>
        <p>Jedes Projekt ist nur so gut, wie die Personen, die das Projekt unterstützen. Hier seht ihr diejenigen, die für Mkx. DV verantwortlich sind.</p>
        <h3>Vorstand</h3>        
        <Row xs={1} md={2} lg={3}>
        <Member name="Tim Güldenpfennig" function="Vorsitzender" src="" />
        <Member name="Marvin Bellmann" function="stellv. Vorsitzender" src=""/>
        </Row><br/>
        <h3>Modulleiter</h3>
        <Row xs={1} md={2} lg={3}>
        <Member name="Tim Hübner" function="Head of MB" src="img/members/huebner.jpg" />
        <Member name="Sami Fatal Al-Yabroudi" function="Head of ET" src="img/members/sami.jpeg"/>
        </Row><br/>
        <h3>Sonstige Mitglieder</h3>
        <Row xs={1} md={2} lg={3}>
        <Member name="Remo-Peter Stiegler" function="ET-try-hard" src="img/members/remo.jpg" />
        <Member name="Dr. habil. Martin Dymek" function="Softwareexperte" src="img/members/martin.jpg"/>
        </Row>
        </div>
    )
 }

 export default Team;
