function Blog() {
    return(
        <div>
        <h1>Blog</h1>
        <p>Hier steht der Blog</p>
        </div>
    )
}

export default Blog;
