function Warn() {
    return(
        <div>
            In Kürze gibt es hier auch etwas zu sehen.
        </div>
    )
}

function Media() {
    return(
        <div>
            <h1>Media</h1>
            <Warn></Warn>
        </div>
    )
} 

function Wallpaper() {
    return(
        <div>
            <h1>Wallpaper</h1>
            <Warn></Warn>
        </div>
    )
} 

function Press() {
    return(
        <div>
            <h1>Presseschau</h1>
            <Warn></Warn>
        </div>
    )
} 

function News() {
    return(
        <div>
            <h1>News</h1>
            <Warn></Warn>
        </div>
    )
} 

function Competition() {
    return(
        <div>
            <h1>Die Competition</h1>
            <Warn></Warn>
        </div>
    )
} 

function History() {
    return(
        <div>
            <h1>Teilnahme an Events</h1>
            <Warn></Warn>
        </div>
    )
} 

function Garage() {
    return(
        <div>
            <h1>Unsere Fahrzeuge</h1>
            <Warn></Warn>
        </div>
    )
} 

function Mkx1() {
    return(
        <div>
            <h1>Mkx. I</h1>
            <Warn></Warn>
        </div>
    )
} 

function Mkx2() {
    return(
        <div>
            <h1>Mkx. II</h1>
            <Warn></Warn>
        </div>
    )
} 

function Mkx3() {
    return(
        <div>
            <h1>Mkx. III</h1>
            <Warn></Warn>
        </div>
    )
} 

function Mkx4() {
    return(
        <div>
            <h1>Mkx. IV</h1>
            <Warn></Warn>
        </div>
    )
} 

function Mkx505() {
    return(
        <div>
            <h1>Mkx. DV</h1>
            <Warn></Warn>
        </div>
    )
} 

function Society() {
    return(
        <div>
            <h1>Unser Verein</h1>
            <Warn></Warn>
        </div>
    )
} 

function Team() {
    return(
        <div>
            <h1>Unser Team</h1>
            <Warn></Warn>
        </div>
    )
} 

function Team22() {
    return(
        <div>
            <h1>Unser Team in der Saison 2022/23</h1>
            <Warn></Warn>
        </div>
    )
} 

function Team21() {
    return(
        <div>
            <h1>Unser Team in der Saison 2021/22</h1>
            <Warn></Warn>
        </div>
    )
} 

function Team20() {
    return(
        <div>
            <h1>Unser Team in der Saison 2020/21</h1>
            <Warn></Warn>
        </div>
    )
} 

function Team19() {
    return(
        <div>
            <h1>Unser Team in der Saison 2019/20</h1>
            <Warn></Warn>
        </div>
    )
} 

function Team18() {
    return(
        <div>
            <h1>Unser Team in der Saison 2018/19</h1>
            <Warn></Warn>
        </div>
    )
} 

function Team17() {
    return(
        <div>
            <h1>Unser Team in der Saison 2017/18</h1>
            <Warn></Warn>
        </div>
    )
} 

function Jobs() {
    return(
        <div>
            <h1>Stellenausschreibungen</h1>
            <Warn></Warn>
        </div>
    )
} 

function Sponsors() {
    return(
        <div>
            <h1>Sponsoren und Unterstützer</h1>
            <Warn></Warn>
        </div>
    )
} 

function GetSponsor() {
    return(
        <div>
            <h1>Sponsor werden</h1>
            <Warn></Warn>
        </div>
    )
} 

function Contact() {
    return(
        <div>
            <h1>Kontakt</h1>
            <Warn></Warn>
        </div>
    )
} 

function Imprint() {
    return(
        <div>
            <h1>Impressum</h1>
            <Warn></Warn>
        </div>
    )
} 

function Dataprotection() {
    return(
        <div>
            <h1>Datenschutz</h1>
            <Warn></Warn>
        </div>
    )
} 

export {Media, Wallpaper, Press, News, Competition, History, Garage, Mkx1, Mkx2, Mkx3, Mkx4, Mkx505, Society, Team, Team22, Team21, Team20, Team19, Team18, Team17, Jobs, Sponsors, GetSponsor, Contact, Imprint, Dataprotection};