import Carousel from 'react-bootstrap/Carousel';

function Slider() {
  return (
    <Carousel>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="img/web1.png?text=First slide&bg=373940"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>text</h3>
          <p>text</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}
export default Slider;
