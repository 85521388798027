import './App.css';
import Navigation from './Navigation';
import Footer from './Footer';
import './tucr-cd.css';
import React, { useRef, useState } from 'react';
import Slider from './structure/Slider.jsx'
import Home from './structure/Home.jsx';
import Blog from './structure/Blog.jsx';
import Imprint from './structure/Impressum.jsx';
import Society from './structure/Verein.jsx';
import Dataprotection from './structure/Datenschutz.jsx';
import Contact from './structure/Kontakt.jsx';
import Sponsors from './structure/SponsorenUnterstuetzer.jsx';
import Team22 from './structure/Team.jsx'
import {Media, Wallpaper, Press, News, Competition, History, Garage, Mkx1, Mkx2, Mkx3, Mkx4, Mkx505, Team, Team21, Team20, Team19, Team18, Team17, Jobs, GetSponsor} from './structure/todo.jsx';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Rollout from './countdown/rollout.jsx';

function App() {
  return (
    <div className="App">
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,100,0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Slab:wght@500&display=swap" rel="stylesheet"></link>
      <Navigation></Navigation>
      <Router><Routes><Route path='/home' element={<Slider ></Slider>}/></Routes></Router>
      <header className="App-header">
        <Router>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Rollout />} />

            <Route path="/blog" element={<Blog />} />
            <Route path="/media"  element={<Media />} />
            <Route path="/wallpaper"  element={<Wallpaper />} />
            <Route path="/press"  element={<Press />} />
            <Route path="/news"  element={<News />} />

            <Route path="/competition" element={<Competition />} />
            <Route path="/history" element={<History />} />

            <Route path="/garage" element={<Garage />} />
            <Route path="/mkx1" element={<Mkx1 />} />
            <Route path="/mkx2" element={<Mkx2 />} />
            <Route path="/mkx3" element={<Mkx3 />} />
            <Route path="/mkx4" element={<Mkx4 />} />
            <Route path="/mkx505" element={<Mkx505 />} />

            <Route path="/society" element={<Society />} />
            <Route path="/team" element={<Team />} />
            <Route path="/team22" element={<Team22 />} />
            <Route path="/team21" element={<Team21 />} />
            <Route path="/team20" element={<Team20 />} />
            <Route path="/team19" element={<Team19 />} />
            <Route path="/team18" element={<Team18 />} />
            <Route path="/team17" element={<Team17 />} />

            <Route path="/jobs" element={<Jobs />} />

            <Route path="/sponsors" element={<Sponsors />} />
            <Route path="/getSponsor" element={<GetSponsor />} />

            <Route path="/kontakt" element={<Contact />} />

            <Route path="/dataprotection" element={<Dataprotection />} />
            <Route path="/imprint" element={<Imprint />} />
          </Routes>
        </Router>
      </header>
      <Footer/>
    </div>
  );
}
export default App;