
import { useEffect, useState } from 'react';
import './countdown.css';

function useCountdown(targetDate) {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
}

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
  
    if (days + hours + minutes + seconds <= 0) {
      return <ExpiredNotice />;
    } else {
      return (
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      );
    }
  };

  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="show-counter">
          <DateTimeDisplay value={days} type={' Tage'} isDanger={days <= 3} /> 
          <DateTimeDisplay value={hours} type={' St.'} isDanger={false} /> 
          <DateTimeDisplay value={minutes} type={' Min.'} isDanger={false} /> 
          <DateTimeDisplay value={seconds} type={' Sek.'} isDanger={false} />
      </div>
    );
  };

  const DateTimeDisplay = ({ value, type, isDanger }) => {
    return (
      <span className={isDanger ? 'countdown danger' : 'countdown'}>
        {value} {type}&nbsp;
      </span>
    );
  };

function ExpiredNotice() {
    return(<div></div>);
}
function Countdown() {
    let day = new Date(1684860300000);
    return(
        <div>
            <CountdownTimer targetDate={day}></CountdownTimer>
        </div>
    );
}
export default Countdown;