import {Container, Row, Col} from 'react-bootstrap';

function Footer(){
    return(
        <div id="footer">
            <div id="footer-in">
            <Container>
                <Row xs={1} md={2} lg={3} className="g-4">
                    <Col>
                        <h2>Rechtliches</h2>
                        <br />
                        <a href="/imprint">Impressum</a>
                        <br />
                        <a href="/kontakt">Kontakt</a>
                        <br />
                        <a href="/dataprotection">Datenschutz</a>
                        <br />
                        <br />
                    </Col>
                    
                    <Col>
                    <h2>Unterstützung</h2><br />
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                    <input type="hidden" name="cmd" value="_s-xclick"/>
                    <input type="hidden" name="hosted_button_id" value="KFF7XUPGZ7CJ4"/>
                    <input type="image" src="https://tuc-racing.de/wp-content/uploads/2020/09/paypal_logo.png" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Spenden mit dem PayPal-Button" width="250" border="0" />
                    <img alt="" src="https://www.paypal.com/de_DE/i/scr/pixel.gif" wc6l9mai3="" width="1" height="1" border="0" />
                    </form>
                    </Col>
                </Row>
            <br/><p>T.U.C. Racing e.V. © 2015-2023</p><a href="https://sites.google.com/tuc-racing.de/team/startseite">Wiki</a>
            </Container>
            
            </div>
        </div>
    );
}
export default Footer;