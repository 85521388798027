import Slider from './Slider.jsx';
function Home() {
    return (
        <div>
        <h1>T.U.C. Racing</h1>
        <p>Chemniter Studenten aller Fakultäten haben ein Ziel: <br/>Mit unseren selbstentwickelten Rennfahrzeugen über die Rennstrecken Europas fahren.</p>
    <br />
    <iframe width="1000" height="500" src="https://www.youtube.com/embed/hPfaNRScPQM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    )
}

export default Home;