import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

function Member(props) {
    return(
        <Col>
        <Card>
            <Card.Img variant="top" src={props.src}/>
            <Card.Body>
                <Card.Title>
                    {props.name}
                </Card.Title>
                <Card.Text>
                    {props.function}
                </Card.Text>
            </Card.Body>
        </Card><br></br></Col>
    )
}

export default Member;