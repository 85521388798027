 function Society()
 {
    return(
        <div>
            <h1>Unser Verein</h1>
            <p>Mit ca. 60 aktiven Mitglieder sind wir die größte studentische Initiative der TU Chemnitz.</p>
            <p>Seit unserem Gründungsjahr 2015 ist es stets unser Ziel uns im Rahmen der Wettbewerbe der Formula Student gegen die rund 1&nbsp;000 anderen Teams weltweit zu behaupten.</p>
        </div>
    );
 }
export default Society;