import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Form, Button} from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './logo1.svg';

function Navigation() {
  return (
    <Navbar sticky="top" bg="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/"><img src={logo} height="50px"></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end ms-auto">
            <Nav.Link href="/home">Home</Nav.Link>
            {/*
            <NavDropdown title="Media" id="basic-nav-dropdown">
              <NavDropdown.Item href="/blog">Blog</NavDropdown.Item>
              <NavDropdown.Item href="/media">Social Media</NavDropdown.Item>
              <NavDropdown.Item href="/wallpaper">Wallpaper</NavDropdown.Item>
              <NavDropdown.Item href="/press">Presse</NavDropdown.Item>
              <NavDropdown.Item href="/news">Newsletter</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Formula Student" id="basic-nav-dropdown">
              <NavDropdown.Item href="/competition">Der Wettbewerb</NavDropdown.Item>
              <NavDropdown.Item href="/history">FS Historie</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Garage" id="basic-nav-dropdown">
              <NavDropdown.Item href="/garage">Rennboliden</NavDropdown.Item>
              <NavDropdown.Divider></NavDropdown.Divider>
              <NavDropdown.Item href="/mkx1">Mkx. I</NavDropdown.Item>
              <NavDropdown.Item href="/mkx2">Mkx. II</NavDropdown.Item>
              <NavDropdown.Item href="/mkx3">Mkx. III</NavDropdown.Item>
              <NavDropdown.Item href="/mkx4">Mkx. IV</NavDropdown.Item>
              <NavDropdown.Item href="/mkx505">Mkx. DV</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Über Uns" id="basic-nav-dropdown">
              <NavDropdown.Item href="/society">Verein</NavDropdown.Item>
              <NavDropdown.Divider></NavDropdown.Divider>
              <NavDropdown.Item href="/team">Team</NavDropdown.Item>
              <NavDropdown.Item href="/team22">2022/2023</NavDropdown.Item>
              <NavDropdown.Item href="/team21">2021/2022</NavDropdown.Item>
              <NavDropdown.Item href="/team20">2020/2021</NavDropdown.Item>
              <NavDropdown.Item href="/team19">2019/2020</NavDropdown.Item>
              <NavDropdown.Item href="/team18">2018/2019</NavDropdown.Item>
              <NavDropdown.Item href="/team17">2017/2018</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Mitglied werden" id="basic-nav-dropdown">
              <NavDropdown.Item href="/jobs">Stellenausschreibungen</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Partner" id="basic-nav-dropdown">
              <NavDropdown.Item href="/sponsors">Sponsoren und Unterstützer</NavDropdown.Item>
              <NavDropdown.Item href="/getSponsor">Sponsor werden</NavDropdown.Item>
  </NavDropdown>*/}
            <Nav.Link href="/kontakt">Kontakt</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
