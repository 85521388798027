import Countdown from "./countdown.jsx";
import './rollout.css';

function Rollout() {
    return(
        <div className="box">
            <h1 className="h1">Mkx. DV Reveal</h1>
            <Countdown />

        </div>
    );
}

export default Rollout;