import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

function SpCard(props) {
    return(
        <Col>
        <Card>
            <Card.Img variant="top" src={props.src}/>
            <Card.Body>
                <Card.Title>{props.firma}</Card.Title>
                <Card.Text>{props.text}</Card.Text>
            </Card.Body>
        </Card></Col>
    )
}

function Sponsors() {
    return(
        <div>
        <h1>Uni-Partner</h1><br/>
        <Row xs={1} md={2} lg={3} className="g-4">
        <SpCard  firma="Maschinenbau" src="../img/tu.png" />
        <SpCard  firma="Professur AWI" src="../img/awi.png" />
        <SpCard  firma="Professur ALF" src="../img/alf.png" />
        <SpCard  firma="IWP" src="../img/iwp.png" />
        </Row><br/><br></br>
        <h1>Hauptsponsoren</h1><br/>
        <Row xs={1} md={2} lg={3} className="g-4">
        <SpCard  firma="Siemens" src="../img/siemens.png" />
        <SpCard  firma="Werkzeug Eylert" src="../img/eylert.png" />
        </Row>
        </div>
    )
 }

 export default Sponsors;